import React, { useContext, useEffect } from 'react'
import styled, { css } from 'styled-components'

import SEO from '../../components/seo'
import Box from '../../components/box'
import { AuthProvider, AuthContext } from '../../context/auth'
import { H1 } from '..'
import Layout from '../../components/layout'
import checkUtmParams from '../../utils/checkUtmParams'
import useHotjarScript from '../../utils/hooks/useHotjarScript'

const ConflictContainer = () => (
  <AuthProvider>
    <Conflict />
  </AuthProvider>
)

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  margin: auto;
  margin-top: 56px;
  margin-left: 80px;
  margin-bottom: 93px;
`

export const Button = styled.button`
  width: 17.375rem;
  height: 4rem;
  left: 0px;
  top: 0px;
  font-weight: 900;
  font-size: 1.125rem;
  line-height: 1rem;
  color: #ffffff;
  text-transform: capitalize;
  border: none;
  background: black;
  cursor: pointer;
  margin-bottom: 0.75rem;
  font-family: ${props => props.theme.typography.fontFamilyGT};
  font-style: normal;
  ${props =>
    props.disabled
      ? css`
          background-color: #f6f6f6;
          color: #afafaf;
        `
      : ''};
`

export const CopySubText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.75rem;
  margin-bottom: 1.5rem;
  color: #757575;
`

export const MiscText = styled.span`
  font-family: ${props => props.theme.typography.fontFamilyGTMono};
  line-height: 28px;
  font-style: normal;
  font-weight: normal;
  color: #757575;
`

export const MiscTextLink = styled.a`
  font-family: ${props => props.theme.typography.fontFamilyGTMono};
  line-height: 28px;
  font-style: normal;
  font-weight: normal;
  color: black;
  text-decoration: underline;
`

export const ErrorBanner = styled.div`
  background-color: #fbefee;
  font-family: ${props => props.theme.typography.fontFamilyGTMono};
  font-size: 12px;
  line-height: 22px;
  border: 1px solid #ec666a;
  padding: 12px;
`

export const WarningBanner = styled.div`
  background-color: #fbf5ff;
  font-family: ${props => props.theme.typography.fontFamilyGTMono};
  font-size: 12px;
  line-height: 22px;
  border: 1px solid #9601ff;
  padding: 12px;
`

export const SuccessBanner = styled.div`
  background-color: #e7f8ee;
  font-family: ${props => props.theme.typography.fontFamilyGTMono};
  font-size: 12px;
  line-height: 22px;
  border: 1px solid #55cf8a;
  padding: 12px;
`

const Conflict = () => {
  const { userSession } = useContext(AuthContext)
  useEffect(() => {
    checkUtmParams()
  }, [])

  useHotjarScript()

  if (!userSession) return null
  return (
    <Layout>
      <SEO title="Conflict" />
      <Container>
        <Box style={{ marginTop: '175px' }}>
          <H1
            style={{
              fontSize: '20px',
              lineHeight: '24px',
              color: 'black',
              marginBottom: '0px',
            }}
          >
            Wait a second! ✋
          </H1>
          <CopySubText
            style={{ width: '503px', height: '44px', marginTop: '16px' }}
          >
            One of your colleagues is already creating your company workspace.
            We recommend you to try back in a few minutes to get in there.
          </CopySubText>
          <br />
          <Box style={{ marginTop: '16px' }}>
            <MiscText>
              Need help? <MiscTextLink>support@pulse.so</MiscTextLink>
            </MiscText>
          </Box>
        </Box>
      </Container>
    </Layout>
  )
}

export default ConflictContainer
